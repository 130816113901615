import React from 'react';
import BaseCardComponent from '../base/base-card';
import BaseInfoComponent from '../base/base-info';
import { MenuItem } from '@/utils/menu';
import BaseEmptyComponent from '../base/base-empty';
import { articleType } from '@/utils/model';
import BaseBookComponent from '../base/base-book';
import { templateTypeEnum } from '@/utils/config';

export default function NewsComponent({ list, menu }: { list: articleType[]; menu: MenuItem }) {
  const path = `/index?id=${menu.id}&categoryId=${
    menu.categories && menu.categories.length ? menu.categories[0].id : ''
  }`;
  return (
    <div className="w-full">
      <BaseCardComponent title={menu?.name} path={path}>
        <div>
          {list.length > 0 ? (
            <>
              {menu.template === templateTypeEnum.Book ? (
                <div className="flex flex-row">
                  {list.map((item) => (
                    <div className="flex-1 mr-2" key={item.id}>
                      <BaseBookComponent bookInfo={item} />
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  {list.map((item) => (
                    <BaseInfoComponent
                      id={item.id}
                      key={item.id}
                      columnId={menu?.id || ''}
                      title={item.title}
                      time={item.updateAt}
                    />
                  ))}
                </div>
              )}
            </>
          ) : (
            <BaseEmptyComponent />
          )}
        </div>
      </BaseCardComponent>
    </div>
  );
}
