import React from 'react';
import Image from 'next/image';

const tabBarStyle: React.CSSProperties = {
  height: '96px',
  borderRadius: '20px',
  color: '#3C3C3C',
  fontSize: '18px',
  fontWeight: '500',
  padding: '0 37px',
};
const subTabBarStyle: React.CSSProperties = {
  height: '72px',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  color: '#fff',
  background: '#578035',
  fontSize: '18px',
  fontWeight: '500',
  padding: '0 37px',
};
const subTabBarItemStyle: React.CSSProperties = {
  padding: '0 1.5vw 0 0',
};
export default function TabbarComponent() {
  const tabList = [
    {
      name: '会员管理',
      url: require('@/assets/images/member.png'),
      key: 'member',
    },
    {
      name: '活动中心',
      url: require('@/assets/images/activity-icon.png'),
      key: 'activity',
    },
    {
      name: '课程中心',
      url: require('@/assets/images/course.png'),
      key: 'course',
    },
    {
      name: '调查统计',
      url: require('@/assets/images/check.png'),
      key: 'check',
    },
    {
      name: '绿色认证',
      url: require('@/assets/images/authentication.png'),
      key: 'authentication',
    },
  ];
  const subMenuList = [
    {
      name: '入会申请',
      path: '',
    },
    {
      name: '管理规定',
      path: '',
    },
    {
      name: '技术鉴定',
      path: '',
    },
    {
      name: '奖项申报',
      path: '',
    },
    {
      name: '示范企业',
      path: '',
    },
    {
      name: '规范企业',
      path: '',
    },
    {
      name: '人才培训',
      path: '',
    },
    {
      name: '商事调节',
      path: '',
    },
    {
      name: '法律服务',
      path: '',
    },
  ];
  return (
    <div className=" bg-white rounded-lg relative z-3">
      <div className="flex" style={tabBarStyle}>
        {tabList.map((item) => (
          <div className="flex-1 flex items-center cursor-pointer" key={item.key}>
            {' '}
            <Image className="mr-2" width={38} height={38} src={item.url} alt={item.name} />
            {item.name}
          </div>
        ))}
      </div>
      <div className="flex tabbar-sub-list" style={subTabBarStyle}>
        {subMenuList.map((item) => (
          <div
            className="flex items-center cursor-pointer"
            style={subTabBarItemStyle}
            key={item.name}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}
