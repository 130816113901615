/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Image from 'next/image';
import { articleType } from '@/utils/model';
import { Typography } from 'antd';
const { Text } = Typography;
const BookBg = require('@/assets/images/book-bg.png');

const bookStyle: React.CSSProperties = {
  // height: '160px',
  // color: '#fff',
  // background: '#364d79',
  marginTop: '20px',
  width: '100%',
  flex: 1,
};
const bookTitleStyle: React.CSSProperties = {
  fontSize: '14px',
  color: '#555555',
  lineHeight: '41px',
  marginTop: '12px',
  width: '100%',
  textAlign: 'center',
};

export default function BaseBookComponent({ bookInfo }: { bookInfo: articleType }) {
  const onClick = (info: articleType) => {
    window.open(info.file);
  };
  return (
    <div>
      <div
        className="w-full e-book-item relative overflow-hidden cursor-pointer"
        onClick={() => onClick(bookInfo)}
        style={bookStyle}
      >
        <Image src={BookBg} alt={''} />
        <img
          className="absolute top-0 left-0"
          style={{ width: '90%', height: '92%' }}
          src={bookInfo.banner}
          alt={''}
        />
      </div>{' '}
      <Text ellipsis={true} style={bookTitleStyle}>
        {bookInfo.title}
      </Text>
    </div>
  );
}
