/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import { articleType } from '@/utils/model';
import React from 'react';
import { Carousel } from 'antd';
import Router from 'next/router';
const imageStyle: React.CSSProperties = {
  // display: 'block',
  maxHeight: '21vw',
  maxWidth: '100%',
  width: '100%',
  objectFit: 'contain',
};

export default function BackgroundComponent({ bannerList }: { bannerList: articleType[] }) {
  const handleClick = (item: articleType) => {
    Router.push(`${item.link}`);
  };
  return (
    <div
      className="w-full custom-banner bg-white overflow-hidden"
      style={{ height: '21vw', width: '100%' }}
    >
      {bannerList.length > 0 ? (
        <Carousel lazyLoad="progressive" autoplay dots={false}>
          {bannerList.map((item) => (
            <div key={item.id}>
              <div
                className="relative flex items-center justify-center overflow-hidden w-full h-full"
                style={{ height: '21vw', width: '100%' }}
                onClick={() => handleClick(item)}
              >
                {/*  + '?x-oss-process=image/resize,w_2560,h_750,m_fixed' */}
                <img src={item.url || item.banner} style={{ ...imageStyle }} />
              </div>
            </div>
          ))}
        </Carousel>
      ) : (
        <div></div>
      )}
    </div>
  );
}
