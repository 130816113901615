/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import { getBanners, getBasicInfo, getIndex } from '@/api/api';
import BackgroundComponent from '@/components/index/background';
import TabbarComponent from '@/components/index/tabbar';
import BannerComponent from '@/components/index/banner';
import ListComponent from '@/components/index/list';
import { articleType } from '@/utils/model';
import { MenuItem } from '@/utils/menu';
import { pageSettingIdEnum } from '@/utils/config';
import Router from 'next/router';
export async function getServerSideProps() {
  const [baseInfo, { data: indexInfo } = { data: {} }, bannerInfo] = await Promise.all([
    getBasicInfo(),
    getIndex(),
    getBanners(),
  ]);
  const indexPage = baseInfo.columnInfo.find(
    (item: MenuItem) => item.id === pageSettingIdEnum.IndexPage,
  );
  return {
    props: {
      baseInfo,
      indexPage,
      advertisements: (indexInfo?.advertisements || []).splice(0, 2),
      indexInfo: Object.assign(indexInfo?.columns || {}, indexInfo?.publications || {}),
      newBannerList: (indexInfo?.tops || []).splice(0, 3),
      bannerList: bannerInfo?.data || [],
    },
  };
}

const Home = ({
  indexInfo,
  indexPage,
  bannerList = [],
  advertisements = [],
  newBannerList = [],
}: {
  indexInfo: any[];
  indexPage: MenuItem;
  bannerList: articleType[];
  newBannerList: articleType[];
  advertisements: articleType[];
}) => {
  console.log({ indexInfo, indexPage });
  const children = indexPage.children || [];
  const handleClick = (item: articleType) => {
    if (item.link) {
      Router.push(`${item.link}`);
    }
  };
  return (
    <>
      <div>
        <div className=" margin-bottom-24">
          <BackgroundComponent bannerList={bannerList} />
        </div>
        <div className="web-container margin-bottom-24 flex">
          <div className="flex-1 overflow-hidden  margin-right-24">
            <BannerComponent bannerList={newBannerList} height="457px" />
          </div>
          {children[0] && (
            <div style={{ width: 'calc(50% - 12px)' }}>
              <ListComponent list={indexInfo[children[0].id as number]} menu={children[0]} />
            </div>
          )}
        </div>
        <div className="web-container margin-bottom-24">
          <TabbarComponent />
        </div>
        {children.length > 1 && (
          <div>
            {/* 490 */}
            {children.map((item, index) => (
              <div className="web-container flex" key={index}>
                {index % 2 === 1 && children[index] && (
                  <div className="flex-1 overflow-hidden   margin-bottom-24">
                    <ListComponent
                      list={indexInfo[children[index].id as number]}
                      menu={children[index]}
                    />
                  </div>
                )}
                {index % 2 === 1 && children[index + 1] && (
                  <div
                    className="flex-1 margin-left-24 margin-bottom-24"
                    style={{ width: 'calc(50% - 12px)' }}
                  >
                    <ListComponent
                      list={indexInfo[children[index + 1].id as number]}
                      menu={children[index + 1]}
                    />
                  </div>
                )}
                {index % 2 === 0 && advertisements[index / 2 - 1] && (
                  <div
                    className="flex-1 overflow-hidden  margin-bottom-24 cursor-pointer"
                    onClick={() => handleClick(advertisements[index / 2 - 1])}
                  >
                    <img src={advertisements[index / 2 - 1].url} />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default Home;
