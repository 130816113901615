import { Space } from 'antd';
import React, { ReactElement } from 'react';
import BaseGradientComponent from './base-gradient';
import Image from 'next/image';
const morePng = require('@/assets/images/more.png');
import Router from 'next/router';
const baseCardStyle: React.CSSProperties = {
  borderRadius: '10px',
  padding: '10px 32px 16px',
};
const baseCardHeaderStyle: React.CSSProperties = {
  padding: '18px 0 13px',
  color: '#555555',
  height: '51px',
};
const baseCardTitleStyle: React.CSSProperties = {
  height: '20px',
  fontSize: '18px',
  fontWeight: 500,
  lineHeight: '20px',
};
const baseCardTitleBorderStyle: React.CSSProperties = {
  height: '3px',
  borderRadius: '10px',
  bottom: '-13px',
};
const baseCardMoreStyle: React.CSSProperties = {
  borderTop: '1px solid #EBEEF5',
  height: '52px',
  marginBottom: '-16px',
};
const baseCardMoreTitleBorderStyle: React.CSSProperties = {
  borderRadius: '10px',
  bottom: '-12px',
  height: '20px',
  fontSize: '14px',
  color: '#555',
  fontWeight: 500,
  lineHeight: '20px',
};
export default function BaseCardComponent({
  title,
  children,
  path = '',
  hideBorder,
}: {
  title?: string;
  path?: string;
  children: ReactElement;
  hideBorder?: boolean;
}) {
  const onClickMore = () => {
    Router.push(path);
  };
  return (
    <div className="base-card bg-white w-full" style={baseCardStyle}>
      {!!title && (
        <div
          className="base-card-header flex justify-between border-b-2  border-gray-200"
          style={baseCardHeaderStyle}
        >
          <span className="text-primary relative" style={baseCardTitleStyle}>
            {title}
            {!hideBorder && (
              <BaseGradientComponent className="absolute" style={baseCardTitleBorderStyle} />
            )}
          </span>
          <span className="cursor-pointer" onClick={() => onClickMore()}>
            <Space size={2} className="cursor-pointer" style={baseCardMoreTitleBorderStyle}>
              更多
              <Image src={morePng} alt="" width="18" height="14" />
            </Space>
          </span>
        </div>
      )}
      {children}
      {!title && (
        <div className="flex items-center justify-center" style={baseCardMoreStyle}>
          <span className="cursor-pointer" onClick={() => onClickMore()}>
            <Space size={2} className="cursor-pointer" style={baseCardMoreTitleBorderStyle}>
              更多
              <Image src={morePng} alt="" width="18" height="14" />
            </Space>
          </span>
        </div>
      )}
    </div>
  );
}
