import React from 'react';
import { Typography } from 'antd';
import Router from 'next/router';
import { columnIdType } from '@/utils/model';
const { Text, Paragraph } = Typography;
const baseInfoStyle: React.CSSProperties = {
  padding: '14px 0 2px',
  borderRadius: '10px',
  bottom: '-13px',
  width: '100%',
};
const baseInfoTitleStyle: React.CSSProperties = {
  height: '26px',
  fontSize: '16px',
  fontWeight: 500,
  color: '#3C3C3C',
  lineHeight: '26px',
  marginBottom: 4,
};
const baseInfoSubTitleStyle: React.CSSProperties = {
  fontSize: '14px',
  color: '#3C3C3C',
  lineHeight: '20px',
  marginBottom: 4,
};
const baseInfoTimeStyle: React.CSSProperties = {
  // fontSize: '14px',
  // color: '#909399',
  // lineHeight: '20px',
  // marginBottom: 4,
  // display: 'flex',
};

export default function BaseInfoComponent({
  id,
  title,
  time,
  // desc,
  subTitle,
  keyword,
  className,
  categoryId = '',
  columnId,
}: {
  id: columnIdType;
  title: string;
  keyword?: string;
  subTitle?: string;
  author?: string;
  time?: string;
  columnId: columnIdType;
  desc?: string;
  className?: string;
  categoryId?: string;
}) {
  const handleClick = () => {
    if (!keyword) {
      Router.push(`/index/detail?columnId=${columnId}&id=${id}&categoryId=${categoryId}`);
    } else {
      window.open(`/index/detail?columnId=${columnId}&id=${id}&categoryId=${categoryId}`);
    }
  };
  return (
    <span className="flex items-center justify-between">
      <div
        className={'base-info w-full ' + className}
        style={{
          ...baseInfoStyle,
        }}
      >
        <div>
          <Text
            ellipsis={true}
            className="font-semibold base-info-title text-color cursor-pointer time-text"
            style={baseInfoTitleStyle}
            onClick={() => handleClick()}
          >
            {title}
          </Text>
        </div>
        {subTitle && (
          <div className="description-text">
            <Paragraph ellipsis={{ rows: 2 }} style={baseInfoSubTitleStyle}>
              {subTitle}{' '}
            </Paragraph>
          </div>
        )}

        <div style={baseInfoTimeStyle} className="time-text flex">
          {/* {author && (
            <Text ellipsis={true} className="author-text">
              {author}
            </Text>
          )} */}
          <span>{time}</span>
        </div>
        {/* {desc && (
          <div>
            <Paragraph ellipsis={{ rows: 2 }} className="description-text">
              {desc}
            </Paragraph>
          </div>
        )} */}
      </div>
      {/* {type === IndexTypeEnum.Award && (
        <div>
          <span style={baseInfoTimeStyle}>{time?.substring(0, 10)}</span>
        </div>
      )} */}
    </span>
  );
}
