import React from 'react';
export default function BaseGradientComponent({
  children,
  className,
  style = {},
}: {
  children?: any;
  className?: string;
  style?: object;
}) {
  return (
    <div
      className={className + ' rounded w-full '}
      style={{
        background: 'linear-gradient(8deg, #DDEC9F 0%, #29720E 100%)',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
