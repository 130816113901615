import { Empty } from 'antd';
import React from 'react';

export default function BaseEmptyComponent() {
  return (
    <div className=" p-4">
      <Empty description={false} />
    </div>
  );
}
