/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Carousel, Typography } from 'antd';
import BaseEmptyComponent from '../base/base-empty';
import { articleType } from '@/utils/model';
import Router from 'next/router';
const { Text } = Typography;
const modalStyle: React.CSSProperties = {
  height: '60px',
  background: 'rgba(134,134,134,0.5)',
  borderRadius: '0px 0px 10px 10px;',
  bottom: 0,
  left: 0,
  width: '100%',
};
const imageStyle: React.CSSProperties = {
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  margin: 'auto',
  objectFit: 'cover',
};

const bannerTextStyle: React.CSSProperties = {
  maxWidth: '400px',
  fontSize: '18px',
  fontWeight: 500,
  paddingLeft: '24px',
};

export default function BannerComponent({
  bannerList,
  height,
  width,
  type,
  hideDots,
}: {
  bannerList: articleType[];
  width?: string;
  height: string;
  type?: string;
  hideDots?: boolean;
}) {
  const [styleList] = useState<any[]>([]);
  const handleClick = (item: articleType) => {
    if (type) {
      Router.push(`/index/detail?type=${type}&id=${item.id}`);
    } else {
      if (item.link) {
        Router.push(`${item.link}`);
      }
    }
  };
  // const handleWindowResize = () => {
  //   console.log(styleList);
  // };
  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);

  // const handleOnImageOnload = (e: any, index: number) => {
  // const list = [...styleList];
  // const { naturalHeight, naturalWidth, clientWidth, clientHeight } = e.target;
  // if (naturalHeight / naturalWidth >= clientHeight / clientWidth) {
  //   // list[index] = {width: clientWidth + 'px' }
  //   list[index] = { width: width || '100%', dom: e.target };
  // } else {
  //   list[index] = { height, dom: e.target };
  // }
  // setStyleList(list);
  // };
  return (
    <div
      className="w-full custom-banner bg-white"
      style={{
        height: height,
        width: width || '100%',
        borderRadius: !hideDots ? '10px' : 0,
        overflow: 'hidden',
      }}
    >
      {bannerList.length ? (
        <Carousel lazyLoad="progressive" autoplay dots={!hideDots}>
          {bannerList.map((item, index) => (
            <div
              className="relative flex items-center justify-center overflow-hidden w-full h-full"
              onClick={() => handleClick(item)}
              key={item.id}
            >
              <img
                src={item.url || item.banner}
                style={
                  styleList[index]
                    ? { ...imageStyle, ...styleList[index] }
                    : { ...imageStyle, minHeight: height, minWidth: width || '100%' }
                }
              />
              <div style={{ height: height, width: width || '100%' }}></div>
              {!hideDots && (
                <div
                  className="absolute z-10 bottom-0 left-0 flex items-center"
                  style={{ ...modalStyle }}
                >
                  <Text className="text-white" ellipsis={true} style={bannerTextStyle}>
                    {' '}
                    {item.title}
                  </Text>
                </div>
              )}
            </div>
          ))}
        </Carousel>
      ) : (
        <BaseEmptyComponent />
      )}
    </div>
  );
}
